.chat-bubble {
  padding: 10px;
  font-family: 'MetroSans', sans-serif;
  line-height: 140%;
}

.user {
  max-width: 70%;
  margin-top: -12px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 8px 8px 2px 8px;
  align-self: flex-end;
  background-color: #bfc6aa;
  color: black;
}

.bot-container {
  width: 100%;
  margin-bottom: 2px;
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-image: url('../images/future-icon.png');
  background-color: #152724;
  background-position: center center;
  background-repeat: no-repeat;
}

.bot {
  max-width: 70%;
  border-radius: 8px 8px 8px 2px;
  background-color: rgba(179, 161, 147, 0.25);
  color: #152724;
  white-space: pre-line;
}

span {
  width: 7px;
  height: 7px;
  float: left;
  background: #152724;
  margin: 5px;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

span:nth-child(1) {
  animation-delay: 0s;
}

span:nth-child(2) {
  animation-delay: 0.2s;
}

span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.option {
  width: fit-content;
  margin-left: 40px;
  margin-bottom: 5px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  font-family: 'MetroSans', sans-serif;
  line-height: 140%;
  cursor: pointer;
}

.selected-option {
  background-color: #152724;
  color: #fcf0e3;
  cursor: default;
}

.feedback-container {
  margin-left: 50px;
}

.feedback-container svg {
  margin-right: 5px;
  color: rgba(21, 29, 36, 0.7);
  cursor: pointer;
}

.modal-container {
  width: 30%;
  height: 25%;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(4, 7, 7, 0.3);
  padding: 30px;
  background-color: #fcf0e3;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-header h3 {
  margin-top: 0;
  color: black;
  font-family: 'MetroSans', sans-serif;
}

.modal-header svg {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 18px;
}

.feedback-input {
  width: calc(100% - 20px);
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  padding: 10px;
  flex-grow: 1;
  background-color: rgba(179, 161, 147, 0.2);
  resize: none;
  color: black;
  font-family: 'MetroSans', sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.feedback-input:focus {
  outline: 0;
}

.submit {
  width: fit-content;
  align-self: flex-end;
  border: none;
  border-radius: 8px;
  padding: 8px;
  background-color: #152724;
  color: #d5e67e;
  font-family: 'MetroSans', sans-serif;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .user {
    margin-top: 2px;
  }

  .modal-container {
    width: 75%;
    padding: 20px;
  }
}
