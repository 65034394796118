html {
  background-color: #152724;
  font-size: 16px;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "MetroSans";
  src: local("MetroSans"),
  url("./fonts/MetroSans-Medium.otf") format("truetype");
}
