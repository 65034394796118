.app {
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  padding: 40px;
  display: flex;
  align-items: center;
  column-gap: 6%;
}

.conversation-container {
  flex-grow: 1;
  height: calc(100% - 60px);
  background-color: #fcf0e3;
  border-radius: 12px;
  box-shadow: 0 3px 3px 0 #00000040;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.chat-container::-webkit-scrollbar {
  width: 5px;
}

.chat-container::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(179, 161, 147, 0.25);
}

.chat-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #152724;
}

.phantom-div {
  width: 0;
  height: 0;
}

.footnote {
  text-align: center;
  margin-top: 5px;
  color: #b3a193;
  font-family: 'MetroSans', sans-serif;
  font-size: 0.75rem;
}

@media only screen and (max-width: 600px) {
  .app {
    justify-content: flex-start;
  }

  .logo-container {
    position: inherit;
    align-self: flex-start;
    margin: 10px;
  }

  .conversation-container {
    width: calc(100% - 50px);
    height: 77%;
    padding: 25px;
  }
}
