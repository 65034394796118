.input-container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  background-color: rgba(179, 161, 147, 0.2);
}

.input {
  width: calc(100% - 20px);
  background-color: rgba(179, 161, 147, 0);
  border: none;
  padding: 10px;
  resize: none;
  color: black;
  font-family: 'MetroSans', sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.input:focus {
  outline: 0;
}

.send {
  margin: 0 10px 10px 0;
  cursor: pointer;
}
