.nav-bar {
  height: 100%;
}

.nav-bar h1 {
  margin: 0;
  color: #d5e67e;
  font-family: 'MetroSans', sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .nav-bar {
    width: 10%;
    min-width: 0;
  }
}
