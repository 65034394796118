.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(21, 39, 36, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
