.disclaimer-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(4, 7, 7, 0.3);
  padding: 30px;
  background-color: #fcf0e3;
}

.disclaimer {
  margin: 0 0 20px;
  color: black;
  font-family: 'MetroSans', sans-serif;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
}

.confirm {
  width: fit-content;
  border: none;
  border-radius: 8px;
  padding: 8px;
  background-color: #152724;
  color: #d5e67e;
  font-family: 'MetroSans', sans-serif;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .disclaimer-container {
    width: 75%;
    padding: 20px;
  }
}
